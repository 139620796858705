.c-contentBlock {
    .b-captionImg {
        display: inline-block;
        margin-bottom: 36px;
        padding: 6px;
        border: 1px solid #e3e5dc;
        text-decoration: none;
        box-shadow: 2px 2px 8px 0 #ccc;
        display: inline-block;
        padding: 6px;
        border: 1px solid #e3e5dc;
        text-decoration: none;
        margin-bottom: 15px;
        img {
            margin-bottom: 0;
            border: 1px solid #e3e5dc;
        }
        p {
            margin-top: 6px;
            margin-bottom: 0;
            line-height: 1.5;
            text-align: center;
            font-size: 14px;
        }
    }
    .b-notFound {
        text-align: center;
        h1 {
            font-size: 60px;
        }
        h2 {
            margin-bottom: 5px;
        }
        p {
            margin-bottom: 0;
        }
    }
    margin: 0 -15px 30px -15px;
    padding: 32px;
    h1 {
        margin-top: 0;
        margin-bottom: 20px;
        color: $color_6;
        font-size: 32px;
        line-height: 1.4;
        font-family: $primary_font;
        font-weight: bold;
    }
    h2 {
        margin-top: 0;
        color: $color_6;
        font-size: 28px;
        line-height: 1.4;
        font-family: $primary_font;
        font-weight: bold;
        /* border-bottom: 2px solid rgba(26, 26, 26, 0.2); */
        margin-bottom: 20px;
    }
    h3 {
        color: $color_6;
        font-size: 22px;
        line-height: 1.4;
        font-family: $primary_font;
        font-weight: bold;
        /* border-bottom: 1px solid rgba(26, 26, 26, 0.1); */
    }
    h4 {
        padding-top: 10px;
        margin-bottom: 10px;
        color: $color_6;
        font-size: 18px;
        line-height: 1.4;
        font-family: $primary_font;
        font-weight: bold;
    }
    p {
        margin-bottom: 20px;
        color: $color_6;
        font-size: 17px;
        line-height: 1.7;
        letter-spacing: 0.5px;
    }
    p.meta {
        margin-bottom: 10px;
    }
    .b-imageFrame {
        overflow: hidden;
        width: 100%;
        img {
            max-width: none;
        }
    }
    ul {
        margin-bottom: 20px;
        li {
            margin-bottom: 10px;
            font-size: 17px;
            color: $color_6;
            font-weight: 400;
            line-height: 1.7;
        }
    }
    ol {
        margin-bottom: 20px;
        li {
            margin-bottom: 10px;
            font-size: 17px;
            color: $color_6;
            font-weight: 400;
            line-height: 1.7;
        }
    }
    table {
        margin-bottom: 20px;
    }
    .table-responsive {
        margin-bottom: 20px;
        table {
            margin-bottom: 0;
        }
    }
    pre.b-codeBlock {
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: 30px;
        padding: 24px 16px;
        max-height: 600px;
        background: #151A0D;
        border-radius: 0;
        color: $color_10;
        font-family: $mono_font;
        font-size: 15px;
        white-space: nowrap;
    }
    pre {
        margin-bottom: 20px;
        padding: .5em .75em;
        max-height: 600px;
        background-color: $background_color_1;
        border: 1px solid #ccc;
        border-radius: 0px;
        overflow: auto;
        font-size: 15px;
        word-break: normal;
        word-wrap: normal;
        white-space: pre;
        code {
            background: none;
            border-radius: 0;
            border: none;
            padding: 0;
            word-break: keep-all;
            white-space: pre;
            font-size: 15px;
            line-height: 1.2;
        }
    }
    kbd {
        display: inline-block;
        margin: 0 .1em;
        padding: .1em .6em;
        font-size: 11px;
        line-height: 1.4;
        color: $color_11;
        text-shadow: 0 1px 0 #FFF;
        background-color: $background_color_2;
        border: 1px solid #adb3b9;
        border-radius: 3px;
        box-shadow: 0 1px 0 rgba(12, 13, 14, 0.2), 0 0 0 2px #FFF inset;
        white-space: nowrap;
    }
    code {
        display: inline-block;
        font-size: 14px;
        border: 1px solid #ddd;
        background-color: $background_color_1;
        // border-radius: 4px;
        padding: 3px;
        margin: 1px;
        line-height: 1;
        color: $color_8;
        vertical-align: middle;
    }
    .b-youtubeVideo {
        margin-bottom: 30px;
        width: 100%;
        height: 300px;
    }
    .b-addImage {
        display: none !important;
        margin-bottom: 30px;
        margin-left: -21px;
        max-width: none;
    }
    img {
        margin-bottom: 20px;
        max-width: 100%;
    }
}
