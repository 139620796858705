
.c-singlePost {
    margin: 0 -15px;
    padding: 16px;
    padding-bottom: 0;
    .b-postImg {
        position: relative;
        height: 500px;
        background-size: cover;
        &:before {
            position: absolute;
            content: " ";
            z-index: 2;
            width: 100%;
            height: 100%;
            background: rgba(92, 122, 31, 0.5);
            display: block;
        }
        img {
            width: 100%;
        }
    }
    .b-postDetails {
        p {
            font-size: 14px;
            color: $color_9;
        }
        .b-thumb {
            margin-right: 4px;
            width: 25px;
            height: 25px;
            display: inline-block;
            background-size: cover;
            border-radius: 50%;
            vertical-align: middle;
        }
        .b-bullet {
            display: inline-block;
            padding-left: 2px;
            padding-right: 2px;
        }
        .b-icon {
            margin-right: 4px;
            width: 25px;
            height: 25px;
            display: inline-block;
            line-height: 27px;
            border-radius: 50%;
            color: $color_1;
            background: #436303;
            text-align: center;
            font-size: 12px;
        }
        .b-tags {
            list-style: none;
            padding-left: 0;
            li {
                display: inline-block;
            }
            .b-tag {
                padding-left: 8px;
                padding-right: 8px;
                background: #333;
                color: $color_1;
                text-decoration: none;
                display: inline-block;
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                border-radius: 2px;
                vertical-align: middle;
            }
        }
        .b-tag {
            &:hover {
                background: #454545;
            }
        }
        a {
            color: $color_7;
        }
    }
    .b-postHeader {
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: 10px;
        h1 {
            margin-top: 0;
            margin-bottom: 10px;
            font-size: 36px;
            line-height: 1.4;
            font-family: $primary_font;
            font-weight: bold;
        }
        .b-print {
            display: block;
        }
    }
    .b-postDate {
        margin-bottom: 20px;
        color: $color_3;
        font-size: 16px;
        font-weight: 700;
        .b-dayMonth {
            display: inline-block;
        }
        .b-year {
            display: inline-block;
            font-weight: 400;
        }
    }
    .b-postContent {
        h1 {
            color: $color_6;
            font-size: 34px;
            line-height: 1.4;
            font-family: $primary_font;
            font-weight: bold;
            margin: 40px 0 5px 0;
        }
        h2 {
            color: $color_6;
            font-size: 30px;
            line-height: 1.4;
            font-family: $primary_font;
            font-weight: bold;
            margin: 40px 0 5px 0;
        }
        h3 {
            color: $color_6;
            font-size: 26px;
            line-height: 1.4;
            font-family: $primary_font;
            font-weight: bold;
            margin: 20px 0 5px 0;
        }
        h4 {
            padding-top: 10px;
            margin-bottom: 0px;
            color: $color_6;
            font-size: 22px;
            line-height: 1.4;
            font-family: $primary_font;
            font-weight: bold;
        }
        p {
            margin-bottom: 15px;
            color: $color_6;
            font-size: 17px;
            line-height: 1.7;
            font-weight: 400;
        }
        .heading-tag {
            padding: 0px 5px 3px 5px;
            border: 1px solid #c9c9c9;
            margin-right: 5px;
            border-radius: 5px;
            background: #f0f0f0;
            color: #444;
            font-size: 20px;
            letter-spacing: 1px;
        }
        .heading-tags {
            float: right;
            margin: -45px 6px 0 0;
        }
        p.meta {
            margin-bottom: 10px;
        }
        .b-captionImg {
            display: inline-block;
            padding: 6px;
            border: 1px solid #e3e5dc;
            text-decoration: none;
            margin-bottom: 15px;
            .b-imageFrame {
                overflow: hidden;
                width: 100%;
                img {
                    max-width: none;
                }
            }
            img {
                margin-bottom: 0;
                border: 1px solid #e3e5dc;
            }
            p {
                margin-top: 6px;
                margin-bottom: 0;
                line-height: 1.5;
                text-align: center;
                font-size: 14px;
            }
        }
        ul {
            margin-bottom: 20px;
            li {
                margin-bottom: 10px;
                font-size: 17px;
                color: $color_6;
                font-weight: 400;
                line-height: 1.7;
            }
        }
        ol {
            margin-bottom: 20px;
            li {
                margin-bottom: 10px;
                font-size: 17px;
                color: $color_6;
                font-weight: 400;
                line-height: 1.7;
            }
        }
        blockquote {
            font-style: italic;
        }
        table {
            margin-bottom: 20px;
        }
        table.tableCenter {
            margin-left: auto;
            margin-right: auto;
        }
        .table-responsive {
            margin-bottom: 20px;
            table {
                margin-bottom: 0;
            }
        }
        pre.b-codeBlock {
            margin-left: -16px;
            margin-right: -16px;
            margin-bottom: 30px;
            padding: 24px 16px;
            max-height: 600px;
            background: #151A0D;
            border-radius: 0;
            color: $color_10;
            font-family: $mono_font;
            font-size: 15px;
            white-space: nowrap;
        }
        pre {
            margin-bottom: 20px;
            padding: .5em .75em;
            max-height: 600px;
            background-color: $background_color_1;
            border: 1px solid #ccc;
            border-radius: 0px;
            overflow: auto;
            font-size: 14px;
            word-break: normal;
            word-wrap: normal;
            white-space: pre;
            code {
                background: none;
                border-radius: 0;
                border: none;
                padding: 0;
                word-break: keep-all;
                white-space: pre;
                font-size: 13px;
                line-height: 1.3;
            }
        }
        kbd {
            display: inline-block;
            margin: 0 .1em;
            padding: .1em .6em;
            font-size: 11px;
            line-height: 1.4;
            color: $color_11;
            text-shadow: 0 1px 0 #FFF;
            background-color: $background_color_2;
            border: 1px solid #adb3b9;
            border-radius: 3px;
            box-shadow: 0 1px 0 rgba(12, 13, 14, 0.2), 0 0 0 2px #FFF inset;
            white-space: nowrap;
        }
        code {
            display: inline-block;
            font-size: 14px;
            background-color: #ececec;
            // border-radius: 4px;
            padding: 3px;
            // margin: 1px;
            line-height: 1;
            color: $color_8;
            vertical-align: middle;
        }
        .b-youtubeVideo {
            margin-bottom: 30px;
            width: 100%;
            height: 300px;
        }
        .b-addImage {
            display: none !important;
            margin-bottom: 30px;
            margin-left: -21px;
            max-width: none;
        }
        img {
            margin-bottom: 20px;
            max-width: 100%;
        }
    }
}
