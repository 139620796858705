$color_1: #fff;
$color_2: #578300;
$color_3: #5a5214;
$color_4: #676864;
$color_5: #293314;
$color_6: #3e3e3e;
$color_7: #436303;
$color_8: #333;
$color_9: #74766f;
$color_10: #F5A623;
$color_11: #242729;
$color_12: #cecece;
$color_13: #636364;
$color_14: #cdcdcb;
$color_15: #90918c;

$primary_font: "Oxygen", sans-serif;
$mono_font: "Oxygen Mono", monospace;

$background_color_1: #f8f8f8;
$background_color_2: #e1e3e5;
$background_color_3: #ffc;

$border_color_1: #578300;
$border_color_2: red;

// Syntax
$color_s1: #008000;
$color_s2: #0000ff;
$color_s3: #2b91af;
$color_s4: #a31515;
$color_s5: #FF0000;
$background_color_s1: #ffffcc;
