.highlight {
    .hll {
        background-color: $background_color_s1;
    }
    .c {
        color: $color_s1;
    }
    .err {
        border: 1px solid $color_s5;
    }
    .k {
        color: $color_s2;
    }
    .cm {
        color: $color_s1;
    }
    .cp {
        color: $color_s2;
    }
    .c1 {
        color: $color_s1;
    }
    .cs {
        color: $color_s1;
    }
    .ge {
        font-style: italic;
    }
    .gh {
        font-weight: bold;
    }
    .gp {
        font-weight: bold;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
    .gs {
        font-weight: bold;
    }
    .gu {
        font-weight: bold;
    }
    .kc {
        color: $color_s2;
    }
    .kd {
        color: $color_s2;
    }
    .kn {
        color: $color_s2;
    }
    .kp {
        color: $color_s2;
    }
    .kr {
        color: $color_s2;
    }
    .kt {
        color: $color_s3;
    }
    .s {
        color: $color_s4;
    }
    .nc {
        color: $color_s3;
    }
    .ow {
        color: $color_s2;
    }
    .sb {
        color: $color_s4;
    }
    .sc {
        color: $color_s4;
    }
    .sd {
        color: $color_s4;
    }
    .s2 {
        color: $color_s4;
    }
    .se {
        color: $color_s4;
    }
    .sh {
        color: $color_s4;
    }
    .si {
        color: $color_s4;
    }
    .sx {
        color: $color_s4;
    }
    .sr {
        color: $color_s4;
    }
    .s1 {
        color: $color_s4;
    }
    .ss {
        color: $color_s4;
    }
}
